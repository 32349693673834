<template>
    <nav class="navigation" :class="classes">
        <div class="navigation__content">
            <ul class="navigation__content-links">
                <li v-for="link in menuContent.mainMenuLinks">
                    <router-link :to="link.to">{{
                        link.title[$i18n.locale]
                    }}</router-link>
                </li>
            </ul>

            <lang-switch />
        </div>
    </nav>
</template>

<script>
import MenuContent from '@/assets/menu-content.json';
import LangSwitch from '@/components/atoms/LangSwitch.vue';
import { mapActions } from 'vuex';

export default {
    name: 'Navigation',
    components: { LangSwitch },
    data() {
        return {
            hasBackground: false,
        };
    },
    created() {
        window.addEventListener('scroll', this.onScroll);
    },
    computed: {
        classes() {
            return {
                'navigation--has-background': this.hasBackground,
            };
        },
        menuContent() {
            return MenuContent;
        },
    },
    methods: {
        ...mapActions(['setScrolled']),

        onScroll(e) {
            const scrolled =
                (window.scrollY ?? document.documentElement.scrollTop) > 0;
            const scrolledWide =
                (window.scrollY ?? document.documentElement.scrollTop) > 750;
            this.setScrolled({ scrolled, scrolledWide });
            this.hasBackground = scrolled;
        },
    },
};
</script>
