<template>
    <aside class="sidebar">
        <transition-group name="list" tag="ul">
            <li key="contact">
                <router-link
                    class="sidebar__contact"
                    :title="$t('content.contact')"
                    to="/#contact"
                >
                    <fa-icon :icon="['fas', 'envelope']" />
                </router-link>
            </li>

            <li key="scrollTop">
                <button
                    v-show="scrolledWide"
                    class="sidebar__scroll-up"
                    :title="$t('content.scrollTop')"
                    @click.prevent="onScrollUpClick"
                >
                    <fa-icon :icon="['fas', 'arrow-down']" />
                </button>
            </li>
        </transition-group>
    </aside>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Sidebar',
    computed: {
        ...mapState(['scrolledWide']),
    },
    methods: {
        onScrollUpClick() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
    },
};
</script>
