export default {
    en: {
        short: {
            year: 'numeric',
            month: 'short',
        },
        long: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
        },
    },
    de: {
        short: {
            year: 'numeric',
            month: 'short',
        },
        long: {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
        },
    },
};
