<template>
    <section class="about-section" id="about">
        <div class="about-section__content">
            <div class="about-section__content-text">
                <h2>{{ $t('about.headline') }}</h2>
                <p v-html="$t('about.content')"></p>
            </div>
            <div class="about-section__content-image">
                <div class="about-section__content-image-wrapper">
                    <img :src="require('@/assets/images/pc_glasses.webp')" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AboutSection',
};
</script>
