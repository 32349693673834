<template>
    <component
        class="btn"
        :class="classes"
        :is="getComponent"
        :href="href"
        :to="to"
        :type="isSubmitBtn ? 'submit' : 'button'"
    >
        <div class="btn__content">
            <fa-icon
                v-if="icon"
                :icon="icon"
                class="btn__content-icon fas"
                :class="iconClasses"
                :spin="iconRotation"
            ></fa-icon>
            <span>
                <slot></slot>
            </span>
        </div>
    </component>
</template>

<script>
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';

export default {
    name: 'Btn',
    components: { FaIcon },
    props: {
        to: {
            type: String,
        },
        href: {
            type: String,
        },
        type: {
            type: String,
            validator: (val) =>
                ['default', 'hero', 'transparent'].includes(val),
            default: 'default',
        },
        icon: {
            type: Array,
        },
        iconPosition: {
            type: String,
            default: 'end',
            validator: (val) => ['start', 'end'].includes(val),
        },
        badge: {
            type: [String, Number],
        },
        isSubmitBtn: {
            type: Boolean,
            default: false,
        },
        iconRotation: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            return {
                [`btn--${this.type}`]: true,
            };
        },
        iconClasses() {
            return {
                'btn__content-icon--start': this.iconPosition === 'start',
                'btn__content-icon--end': this.iconPosition === 'end',
            };
        },
        getComponent() {
            if (this.to) {
                return 'router-link';
            } else if (this.href) {
                return 'a';
            }

            return 'button';
        },
    },
};
</script>
