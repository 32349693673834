<template>
    <div class="background">
        <vue-particles id="particles" :options="particlesConfig" />
    </div>
</template>

<script>
import particlesConfig from '@/particles-config';

export default {
    name: 'Background',
    computed: {
        particlesConfig() {
            return particlesConfig;
        },
    },
};
</script>
