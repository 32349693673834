<template>
    <div ref="section" class="scroll-section" :class="classes">
        <slot :visible="isVisible" />
    </div>
</template>

<script>
import { isMobile } from '@/utils/is-mobile';

export default {
    data() {
        return {
            isVisible: false,
            threshold: 0,
            observer: null,
        };
    },
    created() {
        if (!this.customThreshold) {
            this.threshold = isMobile() ? 0.25 : 0.5;
        } else {
            this.threshold = this.customThreshold;
        }
    },
    mounted() {
        this.$nextTick(() => {
            let options = {
                root: null,
                rootMargin: '0px',
                threshold: this.threshold,
            };

            this.observer = new IntersectionObserver(this.onIntersect, options);
            this.observer.observe(this.$refs.section);
        });
    },
    props: {
        active: {
            type: Boolean,
            default: true,
        },
        canBecomeInvisible: {
            type: Boolean,
            default: false,
        },
        customThreshold: {
            type: Number,
        },
    },
    methods: {
        onIntersect(entries) {
            if (this.active && entries[0].isIntersecting) {
                this.isVisible = true;
            }
        },
        // TODO
        onLeave(entries) {
            if (!this.canBecomeInvisible) return;

            this.isVisible = false;
        },
    },
    computed: {
        classes() {
            return {
                'scroll-section--visible': this.isVisible,
            };
        },
    },
};
</script>
