<template>
    <static-content-page class="resume" title="">
        <div class="resume-header">
            <h1>Marius <span>Gebhard</span></h1>
        </div>
        <section id="summary">I am a passionate full-stack web developer from Germany with 8 years of
            total experience. My tech-stack includes Vue.js, Laravel and Drupal. In
            addition I realized some app projects with Flutter, GraphQL and Firebase.
        </section>
        <hr>
        <section id="skills">
            <header>
                <h2>{{ $t('resume.skills') }}</h2>
            </header>
            <table>
                <thead>
                <tr>
                    <th>Skill</th>
                    <th>Keywords</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td style="width: 25%;">Frontend</td>
                    <td class="keywords">HTML5 / TWIG SCSS / CSS3 / BEM Javascript / Typescript Webpack Vue.js
                        Flutter
                    </td>
                </tr>
                <tr>
                    <td style="width: 25%;">Backend</td>
                    <td class="keywords">Node.js PHP8 Drupal Firebase GraphQL</td>
                </tr>
                <tr>
                    <td style="width: 25%;">Devops</td>
                    <td class="keywords">Docker NGINX Linux</td>
                </tr>
                </tbody>
            </table>
        </section>
        <hr>
        <section id="employment">
            <header><span class="fa fa-lg fa-building"></span>
                <h2>employment</h2>

            </header>
            <div class="employment-row">
                <h3>
                    <span><em>Full-Stack Web Developer</em>,
                    <a href="https://www.trio-group.de/" target="_blank">trio-group I.AM</a></span>
                    <span class="tenure">2018-07 — Present</span>
                </h3>
                <p>
                    <p>At the trio-group I.AM, I am a versatile web developer creating web applications,
                        websites, and utility tools. My work spans from crafting dynamic web applications
                        to building engaging websites that reflect brand identity. Most commonly
                        I am working with Drupal and Vue..js, thus I have a good understanding
                        of these technologies. Quality, accessibility and a clean, user-friendly
                        design are important to me, for that reason I gained a very advanced knowledge
                        in HTML5 and CSS3 (SCSS).</p>
                </p>
                <ul>
                    <li>Vue.js</li>
                    <li>Laravel</li>
                    <li>Drupal</li>
                    <li>HTML5/SCSS</li>
                </ul>
            </div>
        </section>
    </static-content-page>
</template>

<script>
import StaticContentPage from '@/components/atoms/StaticContentPage.vue';

export default {
    name:       'Resume',
    components: { StaticContentPage },
};
</script>
