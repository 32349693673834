<template>
    <scroll-section>
        <section class="services-section" id="services">
            <div class="services-section__content">
                <h2>{{ $t('services.headline') }}</h2>

                <grid-list>
                    <li v-for="service in services">
                        <service-card :service="service" />
                    </li>
                </grid-list>
            </div>
        </section>
    </scroll-section>
</template>

<script>
import GridList from '@/components/atoms/GridList.vue';
import { mapState } from 'vuex';
import ServiceCard from '@/components/molecules/ServiceCard.vue';
import ScrollSection from '@/components/atoms/ScrollSection.vue';

export default {
    name: 'ServicesSection',
    components: { ScrollSection, ServiceCard, GridList },
    computed: {
        ...mapState(['services']),
    },
};
</script>
