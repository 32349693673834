import { createI18n } from 'vue-i18n';

// Translations
import en from '@/assets/translations/en';
import de from '@/assets/translations/de';

import datetimeFormats from '@/datetime-formats';

export default createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    datetimeFormats,
    messages: {
        en,
        de,
    },
});
