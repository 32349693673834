<template>
    <static-content-page :title="$t('legal.imprint.title')">
        <p v-html="$t('legal.imprint.content')"></p>
    </static-content-page>
</template>

<script>
import StaticContentPage from '@/components/atoms/StaticContentPage.vue';

export default {
    name: 'Imprint',
    components: { StaticContentPage },
};
</script>
