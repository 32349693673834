<template>
    <router-link to="/#contact">
        <div class="service-card">
            <div class="service-card__content">
                <h3 v-html="service.title[$i18n.locale]"></h3>
                <p v-html="service.description[$i18n.locale]"></p>
            </div>
            <div class="service-card__edge">
                <fa-icon :icon="['fas', service.icon]" />
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'ServiceCard',
    props: {
        service: {
            type: Object,
            required: true,
        },
    },
};
</script>
