<template>
    <div role="button" class="project-teaser" @click.prevent="onClick">
        <div class="project-teaser__image" :style="imageStyle" />

        <div class="project-teaser__content">
            <h3 v-html="project.title[$i18n.locale]"></h3>
            <p v-html="project.teaser[$i18n.locale]"></p>

            <btn :icon="['fas', 'fa-arrow-down']">
                {{ $t('projects.viewProject') }}
            </btn>
        </div>
    </div>
</template>

<script>
import Btn from '@/components/atoms/Btn.vue';

export default {
    name: 'ProjectTeaser',
    components: { Btn },
    props: {
        project: {
            type: Object,
            required: true,
        },
        isActive: {
            type: Boolean,
        },
    },
    computed: {
        imageStyle() {
            const img = require(`@/assets/images/${this.project.image}`);

            return {
                backgroundImage: `url(${img})`,
            };
        },
    },
    methods: {
        onClick() {
            if (this.isActive) {
                this.$router.push({
                    name: 'project-detail',
                    params: { slug: this.project.slug },
                });
                this.$emit('clicked');
            } else {
                this.$emit('inactiveClicked');
            }
        },
    },
};
</script>
