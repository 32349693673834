<template>
    <div class="page-not-found">
        <router-link
            :to="{ name: backPage.to }"
            class="static-content-page__back"
        >
            <fa-icon :icon="['fas', 'arrow-down']" />
            {{ $t(backPage.label) }}
        </router-link>

        <div class="page-not-found__content">
            <h1
                class="page-not-found__title"
                v-html="$t('misc.pageNotFound')"
            ></h1>
            <fa-icon :icon="['far', 'frown-open']" />
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';

export default {
    name: 'PageNotFound',
    components: { FaIcon },
    props: {
        backPage: {
            type: Object,
            default: () => ({
                to: 'home',
                label: 'content.goBack',
            }),
        },
    },
};
</script>
