// Vue
import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';

// Components
import ScrollSection from '@/components/atoms/ScrollSection.vue';

// Styles
import '@/scss/_app.scss';

// 3rd
import Particles from '@tsparticles/vue3';
import { loadSlim } from '@tsparticles/slim';
import { register } from 'swiper/element/bundle';

register();

import i18n from '@/i18n';

// FA
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFrownOpen, faClock } from '@fortawesome/free-regular-svg-icons';
import {
    faArrowDown,
    faLink,
    faEnvelope,
    faPaperPlane,
    faPlus,
    faEye,
    faPencil,
    faCode,
    faServer,
    faMobileScreenButton,
    faCheck,
    faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

library.add(
    faArrowDown,
    faGithub,
    faLinkedin,
    faLink,
    faEnvelope,
    faPaperPlane,
    faPlus,
    faEye,
    faPencil,
    faCode,
    faServer,
    faMobileScreenButton,
    faFrownOpen,
    faClock,
    faCheck,
    faCircleNotch,
);

// Create app
const app = createApp(App);

// Uses
app.use(store)
    .use(router)
    .use(Particles, {
        init: async (engine) => await loadSlim(engine),
    })
    .use(i18n);

// Components
app.component('fa-icon', FontAwesomeIcon);
app.component('scroll-section', ScrollSection);

// Mount
app.mount('#app');
