<template>
    <div class="lang-switch">
        <div class="lang-switch__buttons">
            <button
                :title="$t('langSwitch.de')"
                @click="() => onFlagClick('de')"
                :class="{ active: $i18n.locale === 'de' }"
            >
                <img :src="require('@/assets/images/de.svg')" alt="DE" />
            </button>
            <div class="lang-switch__buttons-divider" />
            <button
                :title="$t('langSwitch.en')"
                @click="() => onFlagClick('en')"
                :class="{ active: $i18n.locale === 'en' }"
            >
                <img :src="require('@/assets/images/gb.svg')" alt="GB" />
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'LangSwitch',
    created() {
        const storeState = window.localStorage.getItem('data');
        if (!storeState) return;

        const savedLang = JSON.parse(storeState).language?.replace(
            /[^a-zA-Z0-9]/g,
            '',
        );

        if (savedLang) {
            this.onFlagClick(savedLang);
        }
    },
    computed: {},
    methods: {
        ...mapActions(['setLanguage']),

        onFlagClick(locale) {
            this.$i18n.locale = locale;
            this.setLanguage(locale);
        },
    },
};
</script>
